// /////////////////////////
// @@ BASE
// ////////////////////////

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;

  @include respond(tab-land) {
    // width < 1200px?
    font-size: 56.25%; // 1rem = 9px, 9/16px = 56.25%
  }

  @include respond(tab-port) {
    // width < 900px?
    font-size: 55%; // 1rem = 8px, 8/16px = 50%
  }

  @include respond(big-desktop) {
    // width > 1800px?
    font-size: 75%; // 1rem = 12px, 12/16px = 75%
  }
}

body {
  box-sizing: border-box;
  overflow-x: hidden;
}
