// /////////////////////////
// @@ MIXINS
// ////////////////////////

@mixin respond($breakpoint) {
    @if $breakpoint == smallest-phone {
      @media only screen and (max-width: 20em) {@content};   //320px
    }
     
    @if $breakpoint == small-phone {
      @media only screen and (max-width: 30em) {@content};    //480px
    }
  
    @if $breakpoint == phone {
      @media only screen and (max-width: 37.5em) {@content};  //600px
    }
  
    @if $breakpoint == tab-port {
      @media only screen and (max-width: 56.5em) {@content};   //900px
    }
  
    @if $breakpoint == tab-land {
      @media only screen and (max-width: 75em) {@content};  //1200px
    }
  
    @if $breakpoint == big-desktop {
      @media only screen and (min-width: 112.5em) {@content};  //more than 1800px
    }
  }