.sidebar {
  color: var(--color--white);
  background: var(--color--red);
  padding: 2rem;
  line-height: 4rem;
  grid-column: 1 / 2;
  grid-row: 2 / -1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include respond(phone) {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
  }

  @include respond(small-phone) {
    grid-column: 1 / -1;
    grid-row: 3 / 4;
    line-height: 3rem;
  }

  &__contact {
    margin-bottom: 2rem;

    

    @include respond(small-phone) {
      columns: auto 2;
      display: flex;
      justify-content: space-between;
    }

    &__phone {
      display: block;

      @include respond(phone) {
        display: none;
      }

      &--mobile {
        display: none;

        @include respond(phone) {
          display: block;          
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  &__footer {
    font-size: 1rem;
    position: relative;
    display: flex;
    justify-content: space-between;

    &::before {
      content: "";
      background: var(--color--white);
      height: 1px;
      position: absolute;
      width: 100%;
      top: 0.5rem;
      left: 0;
    }
  }

  &__link {
    color: var(--color--white);
  }
}
