// /////////////////////////
// @@ TYPOGRAPHY
// ////////////////////////

body {
  font: {
    size: 2rem;
    family: Verdana, sans-serif;
  }
  line-height: 3rem;
}

h1,
h2 {
  text-transform: uppercase;
}

h1 {
  margin-bottom: 2rem;
  font-size: 3rem;
}

h2 {
  margin-bottom: 1.8rem;
  font-size: 2.5rem;
}

p {
  margin-bottom: 1rem;
}
